import React, { useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"

import Layout from "../components/layout"
import imageTDM from "../images/tdm_2plastic_bag.jpg"
import SEO from "../components/seo"

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  background-color: #000000;
  background-image: url(${imageTDM});
  background-repeat: no-repeat;
  background-position: 50% 50%;
`

const Description = styled.div`
  z-index: 0;
  display: flex;
  flex-direction: column;
  width: 850px;
  background: #fff;
  flex-shrink: 0;
  height: 100vh;
  border-right: 1px solid #000000;

  @media only screen and (max-width: 760px) {
    width: 100%;
    border-right: none;
  }
`

const Contacts = styled(motion.ul)`
  padding: 0 17px;
  list-style: none;
  text-decoration: none;
  text-transform: uppercase;
  color: #000000;
  margin-top: 77px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-top: 44px;
  padding-bottom: 24px;
  margin-bottom: 0;

  & a {
    text-decoration: none;
    color: #000000;
  }

  @media only screen and (max-width: 760px) {
    margin-top: 120px;
    align-items: flex-start;
    flex-direction: column;
  }
`

const Name = styled(motion.div)`
  display: flex;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  padding: 22px 17px;
`

const MainText = styled(motion.div)`
  max-width: 100%;
  padding: 22px 17px;
  color: #000000;
  padding-right: 76px;
  font-size: 14px;
  line-height: auto;
  position: relative;
`
const Close = styled.div`
  cursor: pointer;
  position: absolute;
  right: 18px;
  top: 18px;
  width: 18px;
  height: 18px;
  opacity: 1;

  &:before,
  &:after {
    position: absolute;
    left: 10px;
    content: " ";
    height: 18px;
    width: 2px;
    background-color: #000000;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  transition: all.2s ease-in-out;

  &:hover {
    &:after {
      background-color: blue;
    }
    &:before {
      background-color: blue;
    }
  }
`

const ContactsPage = () => {
  const [visible, setVisible] = useState(true)

  const goToHomePage = () => {
    setVisible(false)

    setTimeout(() => {
      navigate("/")
    }, 400)
  }

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.2,
      },
    },
  }

  const item = {
    hidden: { opacity: 0, x: -10 },
    show: { opacity: 1, x: 0 },
  }

  const items = {
    hidden: { opacity: 0, x: -20 },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.5,
      },
    },
  }

  return (
    <Layout showHeader={true} mainPage={false}>
      <SEO title="Contacts" />
      <Container>
        <AnimatePresence>
          {visible && (
            <motion.div
              initial={{ opacity: 0, x: -500 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -500 }}
              transition={{ type: "tween", stiffness: 100 }}
            >
              <Description>
                <Contacts variants={container} initial="hidden" animate="show">
                  <motion.li variants={item}>
                    <a href="mailto:contact@shoplift.design" target="blank">
                      contact@shoplift.design
                    </a>
                  </motion.li>
                  <motion.li variants={item}>
                    Köln <a href="tel:+496093248111">+496093248111</a>
                  </motion.li>
                  <motion.li variants={item}>
                    St.PETERSBURG <a href="tel:+79213019072">+79213019072</a>
                  </motion.li>
                  <motion.li variants={item}>
                    <a
                      href="https://www.instagram.com/shoplift_x_88"
                      target="blank"
                    >
                      Instagram
                    </a>
                  </motion.li>
                </Contacts>

                <Name variants={items} initial="hidden" animate="show">
                  Graphic design & art direction / Köln—St.Petersburg
                </Name>

                <MainText variants={items} initial="hidden" animate="show">
                  <Close onClick={goToHomePage} />
                  Shoplift is a Cologne- and St.Petersburg-based design studio
                  that provides full suite of creative services, focusing on
                  digital, web development, print, identity and exhibition. We
                  built a network of creative talents and engaged in ongoing
                  partnerships with artists, photographers, videographers and
                  coders. Next to commissions and collaborations, Shoplift
                  Studio since its foundation initiating self-directed projects,
                  reflecting on modern culture, media and society.
                </MainText>
              </Description>
            </motion.div>
          )}
        </AnimatePresence>
      </Container>
    </Layout>
  )
}

export default ContactsPage
